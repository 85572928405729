import React from 'react';
import PlusIcon from '../assets/images/simulator/PlusIconButton.svg';
import MinusIcon from '../assets/images/simulator/MinusIconButton.svg';
import PercentageBack from '../assets/images/simulator/PercentageBack.svg';
import PercentageBackDark from '../assets/images/simulator/PercentageBackDark.svg';
import PlusIconDark from '../assets/images/simulator/PlusIconDark.svg';
import MinusIconDark from '../assets/images/simulator/MinusIconDark.svg';
import { formatCurrency } from '../utils/format';

const AndinaSimCalculator = ({ decreaseByOne, increaseByOne, contPorcentaje, valorFinAndina, darkMode }) => {

    return (
        <>
            <p className="col-start-1 font-bold">
                Porcentaje de condonación:
            </p>
            <div className="grid grid-cols-[1fr_2fr_1fr] items-center">
                <div></div>
                <div className="grid grid-cols-[1fr_2fr_1fr] sm:grid-cols-3">
                <button 
                    title="Restar porcentaje"  
                    className="p-0 w-auto h-auto flex items-center justify-self-end"
                    onClick={decreaseByOne}
                >
                    {darkMode ? (
                        <img src={MinusIconDark} alt="Minus Icon dark mode"/>
                    ) : (
                        <img src={MinusIcon} alt="Minus Icon"/>
                    )}
                    
                </button>
                <button className="relative" disabled = {true}>
                    {darkMode ? (
                        <img
                            src={PercentageBackDark} 
                            alt="Percentage Icon dark mode" 
                            className="relative bottom-0 w-24 h-24 object-cover" 
                        />
                    ) : (
                        <img
                            src={PercentageBack} 
                            alt="Percentage Icon" 
                            className="relative bottom-0 w-24 h-24 object-cover" 
                        />
                    )}
                    
                <span className="absolute bottom-7 left-5 right-3 text-2xl font-bold text-custom-purple dark:text-custom-green">
                    {contPorcentaje}%
                </span>
                </button>
                <button 
                    title = "Sumar porcentaje" 
                    className="p-0 w-auto h-auto flex items-center justify-items-start"
                    onClick={increaseByOne}
                >
                    {darkMode ? (
                        <img src={PlusIconDark} alt="Plus Icon dark mode"/>
                    ) : (
                        <img src={PlusIcon} alt="Plus Icon"/>
                    )}
                </button>
                </div>
                <div></div>
            </div>

            <p>
                *Calcula los diferentes escenarios de tu condonación.
            </p>
            <p className="col-start-1 font-bold">
                Valor que te financiaríamos:
            </p>
            {formatCurrency(valorFinAndina)}
            <p>*Valor que pagarás si mantienes tu beneficio de condonación. (No incluye tu aporte del 3% al Fondo de Promoción y Reserva + seguro).</p>
        </>
    );


};

export default AndinaSimCalculator;