import React, { useEffect, useState } from 'react';
import Loading from '../../components/shared/Loading';
import ServiceLink from '../../components/FinancingRequest/ServiceLink';
import { ReactComponent as RequestFormIcon } from '../../assets/images/home/request-form.svg';
import { ReactComponent as SupportIcon } from '../../assets/images/home/support-documents.svg';
import { ReactComponent as LegalizationIcon } from '../../assets/images/home/legalization.svg';
import { useStep } from '../../context/step-context';
import { useDialog } from '../../context/dialog-context';
import { useAuth } from '../../context/auth-context';
import { STEP_STATE } from '../../constants/step/step';
import FormService from '../../services/form/form-service';
import { DIALOG } from '../../constants/dialog/dialog';

import EtapaService from '../../services/stage/etapa-service';
import OpenCoopCrmService from '../../services/verification/open-coop-service';
import { Cache } from 'aws-amplify';
import { ALERTDIALOG } from '../../constants/dialog/alertDialog';
import { STEPALERTADMIN } from '../../constants/step/stepAlertAdmin';
import Button from '../../components/DynamicForm/Button';
import FormApplication from '../../components/FinancingRequest/FormApplication';
import { useNavigate } from 'react-router-dom';
import CreditoService from '../../services/credit/credito-service';

import { ReactComponent as Ayuda } from '../../assets/images/header/ayuda.svg';
import ZapsignService from '../../services/process/zapsign-service';
import ConsultGeneral from '../../services/consult/consult-general';
// import Emoji from '../../components/shared/Emoji';

const FinancingRequest = () => {
  const [loading, setLoading] = useState(true);
  const [enableForm, setEnableForm] = useState();
  const dialog = useDialog();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchProLine, setSearchProLine] = useState(true);
  const [enMora, setEnMora] = useState(false);
  let link = false;
  let changeLine = false;
  let changeCodeudor = false;
  let andinaVariant = false;
  let statusRechazado = false;
  let linkClick = null;
  let newCredit = null;
  const {
    step: { stages, loanId, lineaId },
  } = useStep();
  const { universidadId } = useAuth();

  const cacheCredit = Cache.getItem('requestCredit');
  // let alertAdminState = cacheCredit?.estado?.estado;
  const [alertAdminState, setAlertAdminState] = useState(
    cacheCredit?.estado?.estado
  );
  const [stagesNew, setStagesNew] = useState(stages);

  let [descripcionAlert, setDescripcionAlert] = useState('');
  // let [descripcionAlertSubText, setDescripcionAlertSubText] = useState('');
  let [descripcionAlertSubText] = useState('');
  const [viewModalPro, setViewModalPro] = useState(false);

  if (stages['1'].estado === 1 && enMora) {
    getAlert(STEPALERTADMIN.ARREARS);
  }
  else if(alertAdminState && stages['1'].estado !== 1) {
    // console.log('no esta cogiendo la mora');
    getAlert(alertAdminState);
  }

  /*	const showConfirmationDialog = () =>
		dialog.info(
			<div className='text-center'>
				<p className='font-bold'>¡GENIAL!</p>
				<p>Eso es todo por ahora.</p>
				<br />
				<p>
					Revisaremos la información que registraste y en 48 horas
					tendremos una respuesta para ti.
				</p>
				<br />
				<p>
					Equipo Estrella <Emoji symbol='vulcan-salute' className='inline h-5' />
					<Emoji symbol='sparkles' className='inline h-5' />
					<Emoji symbol='star' className='inline h-5' />.
				</p>
			</div>
		); */

  const formAnswered = (loanId) =>
    FormService.getAnswers(loanId)
      .then((response) => response.data?.length > 0)
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  useEffect(() => {
    const updateEnableForm = async () => {
      if (!enableForm && loanId && stages['2'].estado === STEP_STATE.DISABLED) {
        if (lineaId === 2 && universidadId === '2') {
          setEnableForm(!(await formAnswered(loanId)));
        } else setEnableForm(false);
      }
    };
    updateEnableForm();
  }, [stages, loanId, universidadId]);

  useEffect(() => {
    //	enableForm === false && showConfirmationDialog();
  }, [enableForm]);

  useEffect(() => {
    if (loading && stages) setLoading(false);
  }, [stages]);

  const fetchStep = (invokedByClick = false) => {
    if (invokedByClick) {
      window.location.reload();
    }else {
      EtapaService.get({ usuarioId: user.sub })
        .then((response) => {
          if (typeof response.loanId === 'undefined') {
            setSearchProLine(false);
            setDescripcionAlert('SIN INICIAR');
          } else {
            setStagesNew(response.stages);
            consultCredit(response.loanId);
          }
  
          return response;
        })
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });

    }
  };
  useEffect(() => {
    if (user) fetchStep();
  }, [user]);
  const handleClick = () => {
    fetchStep(true); // Indicate that the function was invoked by a click
  };

  if (cacheCredit) {
    let linea =
      (cacheCredit.lineaId === '2' && cacheCredit.universidadId === '2') ||
      (cacheCredit.lineaId === '5' && cacheCredit.universidadId === '3') ||
      (cacheCredit.lineaId === '9' && cacheCredit.universidadId === '2') ||
      (cacheCredit.lineaId === '12' && cacheCredit.universidadId === '6') ||
      (cacheCredit.lineaId === '13' && cacheCredit.universidadId === '5');
    if (
      cacheCredit.linea === 'Línea Pro' ||
      cacheCredit.linea === 'Línea Heroica' ||
      cacheCredit.linea === 'Línea Andina' ||
      cacheCredit.linea === 'Línea Chicamocha' ||
      cacheCredit.linea === 'Línea Curramba'
    ) {
      linea = true;
    }
    if (linea && searchProLine) {
      FormService.getAnswers(cacheCredit.numero)
        .then((response) => {
          if (!(response.data?.length > 0)) {
            // setDescripcionAlert(ALERTDIALOG.P1PENDINGORDERUNDERREVIEW)
            // setDescripcionAlertSubText(ALERTDIALOG.P1PENDINGORDERUNDERREVIEWSUBTEXT)
            setViewModalPro(true);
          }
        })
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
    }
  }
  useEffect(() => {
    if (
      viewModalPro &&
      stages['1'].estado !== 1 &&
      cacheCredit?.estado?.id !== 6 &&
      cacheCredit?.estado?.id !== 4
    ) {
      showFormApplicationDialog();
    }
  }, [viewModalPro]);

  const showFormApplicationDialog = () =>
    dialog.custom(<FormApplication />, {
      className: 'p-2',
      spacing: false,
      actions: (
        <Button
          customColor={'bg-custom-green'}
          onClick={() => {
            if (
              cacheCredit.lineaId === '2' ||
              cacheCredit.linea === 'Línea Pro'
            ) {
              navigate('./registro/linea-pro');
            } else if (
              cacheCredit.lineaId === '5' ||
              cacheCredit.linea === 'Línea Heroica'
            ) {
              navigate('./registro/linea-heroica');
            } else if( 
              cacheCredit.lineaId === '9' ||
              cacheCredit.linea === 'Línea Andina'
            ) {
              navigate('./registro/linea-andina');
            }else if (
              cacheCredit.lineaId === '12' ||
              cacheCredit.linea === 'Línea Chicamocha'
            ) {
              navigate('./registro/linea-pro');
            }else if (
              cacheCredit.lineaId === '13' ||
              cacheCredit.linea === 'Línea Curramba'
            ) {
              navigate('./registro/linea-pro');
            }
            dialog.close();
          }}
        >
          Responder Ahora
        </Button>
      ),
    });

  async function consultCredit(loanId) {
    if (loanId !== undefined) {
      await CreditoService.get(loanId)
        .then((res) => {
          if (res?.estado?.estado) {
            setAlertAdminState(res?.estado?.estado); // res?.estado?.estado
            getAlert(res?.estado?.estado);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function getAlert(alertAdminState) {
    switch (alertAdminState) {
      case STEPALERTADMIN.ARREARS:
        descripcionAlertSubText = ALERTDIALOG.P1ARREARSSUBTEXT;
        break;
      case STEPALERTADMIN.INCOMPLETE:
      
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERUNDERREVIEW;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERUNDERREVIEWSUBTEXT;
        break;
      case STEPALERTADMIN.FACTORY:
        if (descripcionAlert !== ALERTDIALOG.P1PENDINGORDERUNDERREVIEW) {
          descripcionAlert = ALERTDIALOG.P1PENDINGORDERFACTORY;
          descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERFACTORYSUBTEXT;
        }
        break;
      case STEPALERTADMIN.PREAPPROVED:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERPREAPPROVED;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERPREAPPROVEDSUBTEXT;
        break;
      case STEPALERTADMIN.PREAPPROVEDANDINA:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERPREAPPROVED;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGVARIANTSELECTIONANDINA;
        andinaVariant = true;
        break;
      case STEPALERTADMIN.LEGALIZATION:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERLEGALIZATION;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERLEGALIZATIONSUBTEXT;
        break;
      case STEPALERTADMIN.LEGALIZED:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERLEGALIZED;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERLEGALIZEDSUBTEXT;
        break;
      case STEPALERTADMIN.COMMITTEE:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERCOMMITTEE;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERCOMMITTEESUBTEXT;
        linkClick = ALERTDIALOG.P1PENDINGORDERCOMMITTEELINKCLICK;
        changeCodeudor = true;
        break;
      case STEPALERTADMIN.REJECTED:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERREJECTED;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERREJECTEDSUBTEXT;
        statusRechazado = true;
        break;
      case STEPALERTADMIN.CANCELED:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERCANCELED;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERCANCELEDSUBTEXT;
        break;
      case STEPALERTADMIN.RETURNED:
        descripcionAlert = ALERTDIALOG.P1PENDINGORDERRETURNED;
        descripcionAlertSubText = ALERTDIALOG.P1PENDINGORDERRETURNEDSUBTEXT;
        break;
      case STEPALERTADMIN.LINECHANGE:
        descripcionAlert = ALERTDIALOG.LINECHANGE;
        descripcionAlertSubText = ALERTDIALOG.LINECHANGESUBTEXT;
        changeLine = true;
        break;
      default:
        return '';
    }
  }

  const redirectToSimulator = () => {
    console.log(cacheCredit);
    if (cacheCredit.universidad === 'Universidad Externado de Colombia') {
      navigate('/change-line');
    }
    if (cacheCredit.universidad === 'Universidad Tecnológica de Bolivar') {
      navigate('/change-line-utb');
    }
    if (cacheCredit.universidad === 'Universidad de San Buenaventura Cali') {
      navigate('/change-line-usb');
    }
    if (cacheCredit.universidad === 'Universidad Pontificia Bolivariana') {
      navigate('/change-line-upb');
    }
    if (cacheCredit.universidad === 'Universidad de la Costa') {
      navigate('/change-line-cuc');
    }
  };
  const redirectToChangeCodeudor = () => {
    navigate('/change-codeudores');
  };
  const redirectToAndinaVariant = () => {
    navigate('/andina-selection');
  };
  const redirectToPageRejed = () => {
    navigate('/page-rejed');
  };

  // const createHistorialEstadoEtapas = async () => {
  //   const body = {
  //     creditId: cacheCredit.numero,
  //     message: 'Paso 1 estado 2 se Cerró automáticamente',
  //     status: 6,
  //     etapa: 1,
  //     rol: 'createHistorialEstadoEtapas',
  //   };
  //   await ZapsignService.postZapsign(body)
  //     .then((res) => {
  //       if (res) {
  //         console.log(res);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  // const createHistorialEstadoEtapas = async () => {
  //   const body = {
  //     creditId: cacheCredit.numero,
  //     message: 'Paso 1 estado 2 se Cerró automáticamente',
  //     status: 6,
  //     etapa: 1,
  //     rol: 'createHistorialEstadoEtapas',
  //   };
  //   await ZapsignService.postZapsign(body)
  //     .then((res) => {
  //       if (res) {
  //         console.log(res);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const fetchCreditData = async () => {
    if (descripcionAlert !== 'SIN INICIAR' && descripcionAlertSubText !== '') {
      let allNew = true;
      if (cacheCredit.numero !== null) {
        const body = {
          creditId: cacheCredit.numero,
          rol: 'consultRegister',
        };
        await ZapsignService.postZapsign(body) // Pasar la señal del controlador de aborto a la llamada de ZapsignService.postZapsign
          .then(async (res) => {
            if (res.listZapsignUser.length > 0) {
              let re = res.listZapsignUser.filter(
                (r) => r.docNumber.toString() === cacheCredit.estudiante.documento
              );
              if (re[0].status !== 'signed') {
                allNew = false;
              }
              if (cacheCredit?.codeudores[0]?.id !== undefined) {
                re = res.listZapsignUser.filter((r) => {
                  return (
                    r.docNumber.toString() === cacheCredit.codeudores[0].documento
                  );
                });
                if (re[0].status !== 'signed') {
                  allNew = false;
                }
              }
              if (cacheCredit?.codeudores[1]?.id !== undefined) {
                re = res.listZapsignUser.filter(
                  (r) =>
                    r.docNumber.toString() === cacheCredit.codeudores[1].documento
                );
                if (re[0].status !== 'signed') {
                  allNew = false;
                }
              }
            }
            setLoading(false);
            console.log(allNew);            
            // if (!allNew && stages[1].estado === 2) {
            //  await createHistorialEstadoEtapas();
            //  window.location.reload();
            // }
            if (allNew) {
              if (stages[1].estado === 6 && stages[2].estado === 1) {
               // await createHistorialEstadoEtapas();
               const body = {
                    creditId: cacheCredit.numero,
                    message: 'Cerrado automatico para abrir paso 2',
                    status: 2,
                    etapa: 1,
                    rol: 'insertHistorialEstadoEtapas',
                  };
                  await ConsultGeneral.postInsertHistorialEstadoEtapas(body)
                    .then((res) => {
                      if (res) {
                        console.log(res);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
               navigate('/solicitar-financiacion');
                
              }
            }
            if (loanId !== undefined) {
              await CreditoService.get(loanId)
                .then((res) => {
                  // se deberia validar si la cache existe y si la data de la cache es la misma para no remplazar o posiblemente quitarnos una consulta
                  if (res) {
                    newCredit = res;
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }
            if (newCredit?.estado.id === 11 && stages[2].estado === 2) {
              // await createHistorialEstadoEtapas();
              const body = {
                loanIds: cacheCredit.numero,
                statusActual: 1,
                rol: 'cambiarEstadoFabricaSP',
              };
              await ConsultGeneral.postInsertHistorialEstadoEtapas(body)
                .then((res) => {
                  if (res === '') {
                    window.location.reload();
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
                
               
             }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };
  const checkFinancialBlockade = async () => {
    const body = {
      nro_identificacion: user['custom:document'],
      rol: 'consultaOpenCreditosPantalla',
    };
    const resul = await OpenCoopCrmService.postOpenCoop(body);
    if (resul?.listCustomer?.length > 0 && !enMora) {
      
      resul?.listCustomer[0].forEach((item) => {
        if(item.dias_mora >= 5) {
          setEnMora(true);
          return;
        }
      });
    }
  };

  useEffect(() => {
    const viewData = async () => {
      await fetchCreditData();
    };
    viewData();
  }, []);

  useEffect(() => {
    const checkData = async () => {
      await checkFinancialBlockade();
    };
    checkData();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {stagesNew['2'].estado.toString() !== '1' && stagesNew['2'].estado.toString() !== '3' && (
        <a href="https://educacionestrella2.digiturno5.com:5427/Player.aspx?recurso=EduestrellaV4" target="_blank" rel="noreferrer" className="text-right">
          <Button
            className="text-xs text-light shadow-light-active transition duration-500 dark:text-dark dark:shadow-dark-active"
            customColor="bg-light dark:bg-dark"
          >
            <div className="flex flex-row items-center space-x-0 md:space-x-1">
              <Ayuda className="h-10 w-auto fill-current" />
              <p>¡Necesito Ayuda!</p>
            </div>
          </Button>
        </a>
      )}
      
      <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
        Solicitar Financiación
      </h2>
      <h3 className="pb-6 text-center font-bold">
        Disfruta de un proceso 100% digital, fácil y sin papeleos
      </h3>

      <section className="flex-1 space-y-6 text-center">
        <p>
          La solicitud de financiación es un proceso que debe realizar el
          ESTUDIANTE directamente y la información suministrada es personal.
        </p>
        <div>
          <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
            <span>
              <Button onClick={handleClick} customColor="bg-custom-purple">
                Actualizar estado
              </Button>
            </span>
            <br />
            Estado de solicitud: {descripcionAlert}
          </h2>

          <p>
            <b>
              {descripcionAlertSubText}{' '}
              {link ? (
                <a href={linkClick} target="_blank" rel="noopener noreferrer">
                  <span className="underline">Clic aquí</span>
                </a>
              ) : (
                ''
              )}
              {enMora ? (
                <i><br />(Si consideras que es un error, por favor contacta nuestro canal de servicio en el botón superior ¡Necesito Ayuda!)</i>
              ) : (
                ''
              )}
            </b>
            
          </p>
          {changeLine && (
            <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
              <span className="pb-6 text-center text-xl font-bold text-custom-green">
                <Button
                  onClick={redirectToSimulator}
                  customColor="bg-custom-green"
                >
                  Conocer Más
                </Button>
              </span>
            </h2>
          )}
          {changeCodeudor && (
            <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
              <span className="pb-6 text-center text-xl font-bold text-custom-green">
                <Button
                  onClick={redirectToChangeCodeudor}
                  customColor="bg-custom-green"
                >
                  Conocer Más
                </Button>
              </span>
            </h2>
          )}
          {andinaVariant && (
            <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
              <span className="pb-6 text-center text-xl font-bold text-custom-green">
                <Button
                  onClick={redirectToAndinaVariant}
                  customColor="bg-custom-green"
                >
                  Conocer Más
                </Button>
              </span>
            </h2>
          )}
          {statusRechazado && (
            <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
              <span className="pb-6 text-center text-xl font-bold text-custom-green">
                <Button
                  onClick={redirectToPageRejed}
                  customColor="bg-custom-green"
                >
                  Conocer Más
                </Button>
              </span>
            </h2>
          )}
        </div>
        
        <div className="my-4 grid grid-cols-1 gap-4 md:my-6 md:grid-cols-3 md:gap-6">
          <ServiceLink
            link="registro"
            FormIcon={RequestFormIcon}
            state={
              stagesNew[1].estado === stages['1'].estado
                ? stages['1']
                : stagesNew['1']
            }
          />
          <ServiceLink
            link="verificacion"
            FormIcon={SupportIcon}
            state={stages['2']}
          />
          <ServiceLink
            link="legalizacion"
            FormIcon={LegalizationIcon}
            state={stages['3']}
          />
        </div>
      </section>
    </>
  );
};

export default FinancingRequest;
