import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cache } from 'aws-amplify';
import { useDialog } from '../../context/dialog-context';
import useTermSlider from '../../hooks/use-term-slider';
import useInput from '../../hooks/use-input';
import ConstantesSolicitudService from '../../services/shared/constantes-solicitud-service';
import { PROGRAMA_FINANCIAR } from '../../constants/form/validations';
import { DIALOG } from '../../constants/dialog/dialog';
import Button from '../DynamicForm/Button';
import SimuladorInformationApi from './SimulatorInformationApi';
import SimuladorFinanciacionApi from './SimulatorFinanciacionApi';
import SimuladorSinApi from './SimuladorSinApi';
import FormInput from '../Input/FormInput';
import Emoji from '../shared/Emoji';
import { formatCurrency } from '../../utils/format';
import { ReactComponent as InfoIco } from '../../assets/images/info.svg';
import { INFO_TITLE } from '../../constants/shared/infoTitle';

const Simulador = ({ uploadProps, infoOrdenMatricula, fetchedConsultCreditRenovation = null }) => {
  const isMounted = useRef(false);
  const [jornadas, setJornadas] = useState();
  const [periodos, setPeriodos] = useState();
  const [tipoPeriodo, setTipoPeriodo] = useState();
  const dialog = useDialog();
  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const watchUniversidad = fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.universidad.id) : watch('universidad');
  const watchPrograma = fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.programa.cod) : watch('programa');
  const watchNivel = fetchedConsultCreditRenovation ? fetchedConsultCreditRenovation.nivelAcademico.id : watch('nivel');
  const watchLinea = fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.linea.id) : watch('linea');
  const isOtroPrograma = watchPrograma?.toString() === 'otro';
  console.log(fetchedConsultCreditRenovation);
  let watchValorFinanciar = watch('valor_financiar');
  const programas = Cache.getItem(
    `PROGRAMAS_${watchUniversidad}_${watchNivel}`
  );
  const { TermSlider, calculate } = useTermSlider(fetchedConsultCreditRenovation);
  const { InputProps, setLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: PROGRAMA_FINANCIAR,
  });
  const fetchProgramas = async () => {
    if (watchUniversidad && watchNivel && programas === null) {
      setLoading('programa', true);
      await ConstantesSolicitudService.getProgramasByNivelAcademico(
        watchUniversidad,
        watchNivel
      )
        .then((response) =>
          Cache.setItem(
            `PROGRAMAS_${watchUniversidad}_${watchNivel}`,
            response?.concat([
              {
                value: 'otro',
                label: 'Otro',
              },
            ])
          )
        )
        .catch((error) => {
          console.error(error);
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
        });
      setLoading('programa', false);
      setValue('programa', getValues('programa'));
    }
  };
  useEffect(() => {
    fetchProgramas();
  }, [watchUniversidad, watchNivel]);
  const fetchPeriodoAndJornada = async () => {
    setPeriodos();
    setJornadas();
    setTipoPeriodo();
    if (watchPrograma && !isOtroPrograma) {
      setLoading('periodo_cursa', true);
      setLoading('jornada', true);
      await Promise.all([
        ConstantesSolicitudService.getPrograma(watchPrograma)
          .then((response) => {
            setTipoPeriodo(response.tipoPeriodo);
            const periodos = [];
            for (let i = 0; i < response.numeroPeriodos; i++)
              periodos.push({
                value: i + 1,
                label: i + 1,
              });
            setPeriodos(periodos);
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }),
        ConstantesSolicitudService.getJornadasByPrograma(watchPrograma)
          .then((response) => {
            setJornadas(response);
          })
          .catch((error) => {
            console.error(error);
            dialog.info(DIALOG.N11, {
              devInfo: error.message || error.code || error.type,
            });
          }),
      ]);
    }
  };

  useEffect(() => {
    fetchPeriodoAndJornada();
  }, [watchPrograma]);

  useEffect(() => {
    if (periodos) {
      setValue('periodo_cursa', getValues('periodo_cursa'));
      setLoading('periodo_cursa', false);
    }
  }, [periodos]);

  useEffect(() => {
    if (jornadas) {
      setValue('jornada', getValues('jornada'));
      setLoading('jornada', false);
    }
  }, [jornadas]);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const taxCategory = useMemo(() => {
    if( watchUniversidad?.toString() === '2' && tipoPeriodo === 'Trimestre' && watchNivel?.toString() !== '4') {
      return 'estrella_trimestre';
    }

    if (watchLinea === '2') {
      if (tipoPeriodo === 'Año') return 'pro_annual';
      return 'pro';
    } else if (watchLinea === '3') {
      return 'zero';
    } else if (watchLinea === '4') {
      return 'caribe';
    } else if (watchLinea === '5') {
      return 'heroica';
    } else if (watchLinea === '6') {
      return 'tech';
    } else if (watchLinea === '7') {
      return 'pacifico';
    } else if (watchLinea === '8') {
      return 'sultana';
    } else if (watchLinea === '9') {
      return 'andina';
    } else if (watchLinea === '10') {
      return 'barichara';
    } else if (watchLinea === '11') {
      return 'barichara_tec';
    } else if (watchLinea === '12') {
      return 'chicamocha';
    } else if (watchLinea === '13') {
      return 'curramba';
    }else if (tipoPeriodo === 'Año') {
      return 'annual';
    } else return 'estrella';
  }, [watchLinea, tipoPeriodo]);

  useEffect(() => {
    setValue('tax_category', taxCategory);
  }, [taxCategory]);
  const [btnActivo, SetBtnActivo] = useState(false);
  const handleCalculatevalue = async (valueFinance) => {
    let actualValorFinanciar =
      watchLinea?.toString() === '2'
        ? formatCurrency(
          Math.round(parseInt(infoOrdenMatricula.matricula) * 0.65) +
          parseInt(infoOrdenMatricula.seguro)
        )
        : watchLinea?.toString() === '9'? formatCurrency(
          Math.round(parseInt(infoOrdenMatricula.matricula)) +
          parseInt(infoOrdenMatricula.seguro)
        )
        : formatCurrency(Math.round(parseInt(infoOrdenMatricula.valorPago)));
    let inputValueFinance = valueFinance
      ? valueFinance.target.value
      : watchValorFinanciar;

    actualValorFinanciar = actualValorFinanciar.replace(/[$.,]/g, '');
    inputValueFinance = String(inputValueFinance).replace(/[$.,]/g, '');

    actualValorFinanciar = Number(actualValorFinanciar);
    inputValueFinance = Number(inputValueFinance);

    const valorMatriculaDiv = Number(infoOrdenMatricula.matricula)/2;

    if (watchLinea?.toString() === '2') {
      if (
        actualValorFinanciar >= inputValueFinance &&
        inputValueFinance >= 580000 && valorMatriculaDiv <= inputValueFinance 
      ) {
        SetBtnActivo(true);
      } else {
        watchValorFinanciar = 0;
        document.getElementById('btnCalculate').click();
        SetBtnActivo(false);
      }
    } else if (
        actualValorFinanciar >= inputValueFinance &&
        inputValueFinance >= 580000 
      ) {
        
          SetBtnActivo(true);
      } else if(watchLinea?.toString() === '9') {
        watchValorFinanciar = infoOrdenMatricula.valorPago;
  
      } else {
        watchValorFinanciar = 0;
        document.getElementById('btnCalculate').click();
        SetBtnActivo(false);
    }
  };

  useEffect(() => {
    const calculateValuesAndina = async () => {
      if (watchUniversidad && taxCategory && watchLinea.toString() === '9') {
        Cache.setItem('valorFinanciar_andina', infoOrdenMatricula.valorPago);
        await calculate({
          universityId: watchUniversidad,
          amount: infoOrdenMatricula.valorPago,
          taxCategory,
        });
      }
    };
    calculateValuesAndina();
  }, [watchUniversidad, taxCategory]);
  
  useEffect(() => {
    if (infoOrdenMatricula) {
      handleCalculatevalue();
    }
  });

  const watchDepartamentos = watch('incluir_seguro');
  useEffect(() => {
    const updateUrl = async () => {
      if (Number(watchValorFinanciar) === 0) {
        watchValorFinanciar = 0;
        document.getElementById('btnCalculate').click();
        await calculate({
          universityId: watchUniversidad,
          amount: watchValorFinanciar,
          taxCategory,
        });
        
      }
    };
    updateUrl();
    
  }, [watchDepartamentos, btnActivo]);

  const showDialog = (saldo) => {
    saldo !== '-' &&
      watchValorFinanciar !== 0 &&
      dialog.info(
        <div className="space-y-4 text-center">
          <b>
            ¡<Emoji symbol="warning" className="inline h-12" /> Atención!
          </b>
          <p className="font-bold">
            <b>
              Te queda un saldo pendiente de: <br />
              <h4 className="text-center text-2xl font-bold">
                {' '}
                {saldo === '-' ? 0 : saldo} pesos
              </h4>
            </b>
          </p>

          <p className=" text-[#E7496C]">
            Deberás <b>pagarlo a la Universidad directamente</b> cuando ella te
            expida una nueva orden de pago
          </p>
        </div>,
        { onConfirm: () => null }
      );
  };
  return (
    <>
      <section>
        {getValues('nomostra') !== false ? (
          <SimuladorInformationApi infoOrdenMatricula={infoOrdenMatricula} />
        ) : (
          <div></div>
        )}
        {(getValues('nomostra') !== false && watchLinea.toString() !== '9') ? (
          <SimuladorFinanciacionApi infoOrdenMatricula={infoOrdenMatricula} fetchedConsultCreditRenovation = {fetchedConsultCreditRenovation} />
        ) : (
          <div></div>
        )}
        {getValues('nomostra') === false && (
          <SimuladorSinApi uploadProps={uploadProps} fetchedConsultCreditRenovation = {fetchedConsultCreditRenovation} />
        )}
        {getValues('nomostra') !== false && watchLinea.toString() !== '9' && (
          <div>
            <div className=" grid text-right grid-cols-6 mb-3">
              <div className=" m-1 col-span-3">
                <b>
                  <span className="dark:text-custom-green col-span-2 text-[#3F2AAD]">
                    ¿Cuánto necesitas que te
                  </span>
                </b>
                <br />
                <b>
                  <span className="dark:text-custom-green col-span-2 text-[#3F2AAD]">
                    financiemos?
                  </span>
                </b>
              </div>
              <div className=" m-1 col-span-2">
                <FormInput
                  {...InputProps('valor_financiar')}
                  type="currency"
                  onChange={handleCalculatevalue}
                />
              </div>
              <div
                title={INFO_TITLE.SOLICITAR}
                className=" h-5 w-5 content-right"
              >
                <InfoIco className="h-auto w-5 flex-shrink-0 fill-current" />
              </div>
            </div>
            <div className="my-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
              <p className="col-start-2 font-bold text-center">
                ¡Llegó el momento de calcular el tiempo y cuota de tu
                financiación!
              </p>
            </div>

            <div className="my-4 grid grid-cols-2 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
              <div className=" col-start-2 flex justify-center grid-cols-2">
                <Button
                  id="btnCalculate"
                  disabled={!btnActivo}
                  onClick={() =>
                    trigger([
                      'universidad',
                      'nivel',
                      'tiene_financiacion',
                      'valor_matricula',
                      'valor_financiar',
                      'linea',
                      'programa',
                    ]).then(async (isValid) => {
                      if (isValid) {
                        if (
                          watchUniversidad === '2' &&
                          infoOrdenMatricula.valorPago >
                            Number(watchValorFinanciar)
                        ) {
                          showDialog(
                            watchLinea?.toString() === '2'
                              ? formatCurrency(
                                  watchDepartamentos === 'true'
                                    ? Math.round(
                                        parseInt(infoOrdenMatricula.matricula) *
                                          0.65 -
                                          watchValorFinanciar
                                      ) + parseInt(infoOrdenMatricula.seguro)
                                    : Math.round(
                                        infoOrdenMatricula.matricula * 0.65
                                      ) - watchValorFinanciar
                                )
                              : formatCurrency(
                                  watchDepartamentos === 'true'
                                    ? Math.round(
                                        parseInt(infoOrdenMatricula.valorPago) -
                                          watchValorFinanciar
                                      )
                                    : infoOrdenMatricula.matricula -
                                        watchValorFinanciar
                                )
                          );
                        }
                        await calculate({
                          universityId: watchUniversidad,
                          amount: watchValorFinanciar,
                          taxCategory,
                        });
                      }
                    })
                  }
                >
                  Calcular
                </Button>
              </div>
            </div>
            <div className="my-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-4 lg:gap-6">
              <p className="col-span-1">
                <b>Selecciona el plazo</b> en la barra y{' '}
                <b>visualiza el valor aproximado</b> de tu cuota mensual:
              </p>
              <TermSlider
                {...InputProps('plazo')}
                classNameBar="lg:col-span-2 col-span-1"
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Simulador;
