import FormInput from '../components/Input/FormInput';
import Button from '../components/DynamicForm/Button';
// import DisplayValue from '../components/DisplayValue';
// import { formatCurrency } from '../utils/format';
import useInput from '../hooks/use-input';
import { useForm } from 'react-hook-form';
import { useAuth } from '../context/auth-context';
import useTermSlider from '../hooks/use-term-slider';
import { SIMULATOR } from '../constants/form/validations';
import { Cache } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import useVideo from '../hooks/use-video';
import Emoji from '../components/shared/Emoji';
import { useNavigate } from 'react-router-dom';
import ZapsignService from '../services/process/zapsign-service';
import { useDialog } from '../context/dialog-context';
import ConsultGeneral from '../services/consult/consult-general';
import ConstantesSolicitudService from '../services/shared/constantes-solicitud-service';

const ChangeLineUtb = ({ universityId }) => {
  const { user } = useAuth();
  const { TermSlider, calculate } = useTermSlider();
  const [btnActivo, setBtnActivo] = useState(false);
  const [btnChangeLine, setBtnChangeLine] = useState(false);
  const { showVideo } = useVideo();
  const navigate = useNavigate();
  const dialog = useDialog();
  const [fetchedConsultCreditRenovation, setFetchedConsultCreditRenovation] = useState(null);
  const [taxCategory, setTaxCategory] = useState(null);
  const cacheCredit = Cache.getItem('requestCredit');

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: user ? user.given_name + ' ' + user.family_name : undefined,
      phone: user?.phone_number.replace(/^(\+57)/, ''),
      email: user?.email,
      universityId,
      term: 7,
    },
  });
  const watchValorMatricula = watch('registrationAmount');
  let watchValorFinanciar = watch('amount');
  const term = watch('term');
  const { InputProps } = useInput({
    errors,
    getValues,
    register,
    control,
    validations: SIMULATOR,
  });

  useEffect(() => {
    const fetchCreditRenovation = async () => {
      if (cacheCredit) {
        const body = {
          numero: cacheCredit.numero,
          rol: 'consultCreditRenovation',
        };
        const resul = await ConsultGeneral.postConsultCreditRenovation(body);
        setFetchedConsultCreditRenovation(resul);
      }
    };
    fetchCreditRenovation();
  }, []);
  
  useEffect(() => {
    const fetchTaxCategory = async () => {
      if (fetchedConsultCreditRenovation && fetchedConsultCreditRenovation.programa != 'Otro') {
        const resul = await ConstantesSolicitudService.getPrograma(fetchedConsultCreditRenovation.programa.cod);
        if (resul && resul.tipoPeriodo === 'Año') {
          setTaxCategory('annual');
        } else setTaxCategory('estrella');
      }
    };
    fetchTaxCategory();
  }, [fetchedConsultCreditRenovation]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await calculate({
        universityId,
        amount: watchValorFinanciar === 0 ? watchValorFinanciar : data.amount,
        taxCategory,
      });
    } catch (error) {
      setBtnChangeLine(false);
    }
  };
  const handleCalculatevalue = (valueFinance) => {
    const valueWatchValorMatricula = cacheCredit.valorMatricula;

    let inputValueFinance = valueFinance
      ? valueFinance.target.value
      : watchValorFinanciar;

    let actualValorFinanciar = valueWatchValorMatricula;
    inputValueFinance = String(inputValueFinance).replace(/[$.,' ']/g, '');

    actualValorFinanciar = Number(actualValorFinanciar);
    inputValueFinance = Number(inputValueFinance);

    if (
      actualValorFinanciar >= inputValueFinance &&
      inputValueFinance >= 580000
    ) {
      setBtnActivo(true);
      setBtnChangeLine(true);
    } else {
      watchValorFinanciar = 0;
      document.getElementById('btnCalculate').click();
      setBtnActivo(false);
      setBtnChangeLine(false);
    }
  };
  useEffect(() => {
    if (watchValorMatricula) {
      handleCalculatevalue();
    }
  });
  const sendChangeLine = async () => {
    const body = {
      creditoNumero: cacheCredit.numero,
      estado: 8,
      valorFinanciar: watchValorFinanciar,
      plazo: term,
      valorFinanciarAnterior: cacheCredit.valorFinanciar,
      newLine: 1,
      oldLine: 5,
      mensaje:
        'Aceptado por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      rol: 'changeLineStudent',
    };

    await ZapsignService.postZapsign(body)
      .then(() => {
        navigate('/solicitar-financiacion');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const sendChangeLineCancel = async () => {
    const body = {
      creditoNumero: cacheCredit.numero,
      estado: 6,
      mensaje:
        'Desistido por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      rol: 'changeLineStudentCancel',
    };

    await ZapsignService.postZapsign(body)
      .then(() => {
        navigate('/solicitar-financiacion');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const showDialog = (message, callback) => {
    dialog.confirmation(
      <div className="space-y-4 text-center">
        <Emoji symbol="warning" className="inline h-12" />
        <p className="font-bold">{message}</p>
      </div>,
      { onConfirm: callback }
    );
  };
  return (
    <>
      <section>
        <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
          ¿Aceptas Cambio de Línea?
        </h2>
        <h4 className="font-bold">
          Tu solicitud no cumple los parámetros para acceder a la línea de
          financiación con beneficio Tasa 0% de interés de tu Universidad como
          #MiembroDeEducaciónEstrella, sin embargo, te animamos a continuar
          trabajando con la vista fija en el sueño que tienes por delante y te
          ofrecemos una alternativa diferente que te permite financiar tus
          estudios:
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <p>
            <h4 className="pb-6 text-center text-xl font-bold text-custom-green">
              LÍNEA ESTRELLA
            </h4>
          </p>
          <div className="my-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-3 lg:gap-6">
            <p className="col-start-1 font-bold">
              ¿Cuál es el valor de tu matrícula?:
            </p>

            <p>
              ${' '}
              {cacheCredit?.valorMatricula?.toLocaleString('es-ES', {
                minimumFractionDigits: 0, // El número mínimo de decimales
                maximumFractionDigits: 0, // El número máximo de decimales
              })}
            </p>
            <p className="col-start-3 font-bold">
              <>
                Características de la Línea ESTRELLA -{' '}
                <button
                  className="hover:underline"
                  onClick={() => showVideo('OlrT6FPlFMU')}
                >
                  Ver video:{' '}
                  <Emoji symbol="video-camera" className="inline h-4" />
                </button>
              </>
            </p>
            <p className="col-start-1 font-bold">
              ¿Cuál es el valor que necesitas financiar?:
            </p>
            <FormInput
              {...InputProps('amount')}
              placeholder="Valor a financiar"
              type="currency"
              onChange={handleCalculatevalue}
            />
            <p className="col-start-1 font-bold">
              ¡Llegó el momento de calcular el tiempo y cuota de tu
              financiación!
            </p>
            <div className="flex justify-center lg:justify-start">
              <Button
                type="submit"
                className="w-auto lg:w-full"
                loading={isSubmitting}
                disabled={!btnActivo}
                id="btnCalculate"
              >
                Simular
              </Button>
            </div>
          </div>
        </form>

        <div className="mt-4 grid grid-cols-1 items-center gap-4 gap-y-6 lg:my-6 lg:grid-cols-4 lg:gap-6">
          <p className="col-span-1">
            <b>Selecciona el plazo</b> en la barra y{' '}
            <b>visualiza el valor aproximado</b> de tu cuota mensual:
          </p>
          <TermSlider
            {...InputProps('term')}
            classNameBar="lg:col-span-2 col-span-1"
          />
        </div>
        <div>
          <p>
            Al completar los valores y darle clic en <b>Aceptar Línea Estrella</b>
            , aceptas financiar a través de Línea Estrella. Si no deseas
            continuar, por favor dale click en <b>Cancelar Solicitud.</b>
          </p>
        </div>

        <div className="my-4 text-center">
          <Button
            className="mr-4"
            onClick={() =>
              showDialog(
                '¿Confirmas el DESISTIMIENTO de tu solicitud?',
                sendChangeLineCancel
              )
            }
            disabled={!btnChangeLine}
          >
            Desistir Solicitud
          </Button>
          <Button
            onClick={() =>
              showDialog('¿Confirmas EL CAMBIO DE LÍNEA?', sendChangeLine)
            }
            disabled={!btnChangeLine}
          >
            Aceptar Línea Estrella
          </Button>
        </div>
      </section>
    </>
  );
};

export default ChangeLineUtb;
