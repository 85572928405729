import React, { useState, useEffect } from 'react';
import Loading from '../../components/shared/Loading';
import IdentityService from '../../services/user/identity-service';
import { useAuth } from '../../context/auth-context';
import { useDialog } from '../../context/dialog-context';
import { DIALOG } from '../../constants/dialog/dialog';
import Button from '../../components/DynamicForm/Button';
import { ReactComponent as IdentityFailureIcon } from '../../assets/images/identity/identity-failure.svg';
import { ReactComponent as IdentityPendingIcon } from '../../assets/images/identity/identity-pending.svg';
import { ReactComponent as IdentitySuccessIcon } from '../../assets/images/identity/identity-success.svg';
import { ReactComponent as IdentityIcon } from '../../assets/images/identity/identity.svg';
import { ReactComponent as Ayuda } from '../../assets/images/header/ayuda.svg';
import Emoji from '../../components/shared/Emoji';
import LoadingComponent from '../../components/shared/LoadingComponent';
import { IDENTITY_STATE } from '../../constants/shared/identity';
import ShareURL from '../../components/Identity/ShareURL';
import { Link } from 'react-router-dom';
import ZapsignService from '../../services/process/zapsign-service';

const Identity = () => {
  const [loading, setLoading] = useState(true);
  const [identity, setIdentity] = useState();
  const [urlHelp, setUrlHelp] = useState('');
  const [URL, setURL] = useState({
    estudiante: undefined,
    codeudores: {},
  });
  const dialog = useDialog();
  const { user } = useAuth();

  /*
    posible status:
      notCreated
      pending
      failure
      success
  */
  const fetchIdentity = () =>
    IdentityService.get({ usuarioId: user.sub })
      .then((response) => setIdentity(response))
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const fetchURL = (codeudorId) =>
    IdentityService.getURL({
      usuarioId: user.sub,
      ...(codeudorId && { codeudorId }),
      redirectURL: codeudorId
        ? 'https://educacionestrella.com/'
        : 'https://app.educacionestrella.com/verificacion',
    })
      .then((response) => {
        setURL((current) => ({
          ...current,
          ...(codeudorId
            ? {
                codeudores: {
                  ...current.codeudores,
                  [codeudorId]: response.URL,
                },
              }
            : { estudiante: response.URL }),
        }));
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const shouldFetchURL = (statusCodeudor) => {
    if (!identity) return false;
    const statusEstudiante =
      identity.estudiante.processes[0]?.status || IDENTITY_STATE.NOT_CREATED;
    if (statusCodeudor) {
      return (
        statusEstudiante !== IDENTITY_STATE.NOT_CREATED &&
        (statusCodeudor === IDENTITY_STATE.NOT_CREATED ||
          statusCodeudor === IDENTITY_STATE.FAILURE)
      );
    }
    return (
      statusEstudiante === IDENTITY_STATE.NOT_CREATED ||
      statusEstudiante === IDENTITY_STATE.FAILURE
    );
  };

  useEffect(() => {
    fetchIdentity();
  }, []);
  useEffect(() => {
    if (identity) {
      if (shouldFetchURL()) fetchURL();
      identity.codeudores.forEach(async (codeudor) => {
        if (
          shouldFetchURL(
            codeudor.processes[0]?.status || IDENTITY_STATE.NOT_CREATED
          )
        )
          await fetchURL(codeudor.id);
      });
    }
  }, [identity]);

  useEffect(() => {
    if (
      identity &&
      loading &&
      (shouldFetchURL() ? URL.estudiante : true) &&
      !identity.codeudores.find((codeudor) =>
        shouldFetchURL(
          codeudor.processes[0]?.status || IDENTITY_STATE.NOT_CREATED
        )
          ? !URL.codeudores[codeudor.id]
          : false
      )
    )
      setLoading(false);
  }, [identity, URL]);

  const renderIcon = (status) => {
    switch (status) {
      case IDENTITY_STATE.NOT_CREATED:
        return (
          <IdentityIcon className="h-16 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
        );
      case IDENTITY_STATE.PENDING:
        return (
          <IdentityPendingIcon className="h-16 w-auto flex-shrink-0 fill-current text-custom-purple" />
        );
      case IDENTITY_STATE.FAILURE:
        return (
          <IdentityFailureIcon className="h-16 w-auto flex-shrink-0 fill-current text-error" />
        );
      case IDENTITY_STATE.SUCCESS:
        return (
          <IdentitySuccessIcon className="h-16 w-auto flex-shrink-0 fill-current text-custom-green" />
        );
      default:
        return undefined;
    }
  };

  const renderMessage = (status, retry, codeudorId) => {
    if (codeudorId)
      switch (status) {
        case IDENTITY_STATE.NOT_CREATED:
          return (
            <p>
              Comparte el siguiente enlace{' '}
              <Emoji symbol="link" className="inline h-5" /> con tu codeudor,
              para que pueda realizar el mismo proceso
            </p>
          );
        case 'pending':
          return (
            <>
              <p>
                {user.given_name}, estamos verificando la identidad de tu
                codeudor{' '}
                <Emoji symbol="hourglass-done" className="inline h-5" />
              </p>
              {retry && (
                <p className="mt-4 flex items-center space-x-1 text-xs">
                  <span className="opacity-50">
                    ¿No se finalizó correctamente el proceso de verificación?{' '}
                  </span>
                  <LoadingComponent
                    onClick={() => fetchURL(codeudorId)}
                    spinnerClassName="text-custom-purple dark:text-custom-green inline h-5"
                  >
                    <span className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green">
                      Vuelve a intentar
                    </span>
                  </LoadingComponent>
                </p>
              )}
            </>
          );
        case IDENTITY_STATE.FAILURE:
          return (
            <p>
              {user.given_name}, hubo un error con la verificación de identidad
              de tu codeudor{' '}
              <Emoji symbol="cross-mark" className="inline h-5" /> Por favor
              inténtalo nuevamente, recuerda tener tu celular a la mano{' '}
              <Emoji symbol="mobile-phone" className="inline h-5" />
            </p>
          );
        case 'success':
          return (
            <p>
              La verificación de tu codeudor fue exitosa{' '}
              <Emoji symbol="check-mark-button" className="inline h-5" />
            </p>
          );
        default:
          return undefined;
      }
    else {
      switch (status) {
        case IDENTITY_STATE.NOT_CREATED:
          return (
            <p>
              A continuación, podrás iniciar el proceso para verificar tu
              identidad. Recuerda tener tu celular a la mano
              <Emoji symbol="mobile-phone" className="inline h-5" />
            </p>
          );
        case 'pending':
          return (
            <>
              <p>
                Estamos verificando tu identidad{' '}
                <Emoji symbol="hourglass-done" className="inline h-5" />
              </p>
              {retry && (
                <p className="mt-4 flex items-center space-x-1 text-xs">
                  <span className="opacity-50">
                    ¿No finalizaste correctamente el proceso de verificación?{' '}
                  </span>
                  <LoadingComponent
                    onClick={() => fetchURL()}
                    spinnerClassName="text-custom-purple dark:text-custom-green inline h-5"
                  >
                    <span className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green">
                      Vuelve a intentar
                    </span>
                  </LoadingComponent>
                </p>
              )}
            </>
          );
        case IDENTITY_STATE.FAILURE:
          return (
            <p>
              Hubo un error con tu verificación de identidad{' '}
              <Emoji symbol="cross-mark" className="inline h-5" /> Por favor
              inténtalo nuevamente, recuerda tener tu celular a la mano{' '}
              <Emoji symbol="mobile-phone" className="inline h-5" />
            </p>
          );
        case 'success':
          return (
            <p>
              Tu verificación fue exitosa{' '}
              <Emoji symbol="check-mark-button" className="inline h-5" />
            </p>
          );
        default:
          return undefined;
      }
    }
  };

  useEffect(() => {
    const updateUrl = async () => {
      const body = {
        status: 1,
        rol: 'getChangeClickHelp',
      };
      await ZapsignService.postZapsign(body)
        .then((res) => {
          if (res?.length > 0) {
            setUrlHelp(res[0].url);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    updateUrl();
  }, []);

  return (
    <>
      {loading ? (
        <Loading fullScreen={false} />
      ) : (
        <>
          <a href={urlHelp} target="_blank" rel="noreferrer" className="text-right">
            <Button
              className="text-xs text-light shadow-light-active transition duration-500 dark:text-dark dark:shadow-dark-active"
              customColor="bg-light dark:bg-dark"
            >
              <div className="flex flex-row items-center space-x-0 md:space-x-1">
                <Ayuda className="h-10 w-auto fill-current" />
                <p>¡Necesito Ayuda!</p>
              </div>
            </Button>
          </a>
          <div className="flex-1 space-y-8">
            <p className="text-center text-2xl font-bold">
              {`¡Hola ${user.given_name}, bienvenido `}

              <Emoji
                symbol="waving-hand-light-skin-tone"
                className="inline h-8"
              />
              <Emoji
                symbol="smiling-face-with-smiling-eyes"
                className="inline h-8"
              />
              <Emoji symbol="white-exclamation-mark" className="inline h-8" />
            </p>
            <section className="space-y-8">
              <h2 className="font-bold ">Identidad del Estudiante Estrella:</h2>
              <div className="grid gap-4 lg:grid-cols-3">
                <div className="flex flex-row gap-6 lg:col-span-2">
                  {renderIcon(
                    identity.estudiante.processes[0]?.status ||
                      IDENTITY_STATE.NOT_CREATED
                  )}
                  <div>
                    <p className="mb-1 font-bold">
                      {identity.estudiante.nombreCompleto} /{' '}
                      {identity.estudiante.documento}
                    </p>
                    {renderMessage(
                      identity.estudiante.processes[0]?.status ||
                        IDENTITY_STATE.NOT_CREATED,
                      URL.estudiante === undefined
                    )}
                  </div>
                </div>
                {URL.estudiante && (
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <a href={URL.estudiante}>
                      <Button className="w-full">Verificar</Button>
                    </a>
                  </div>
                )}
              </div>
            </section>
            {identity.estudiante.processes[0]?.status &&
              identity.codeudores.map((codeudor) => (
                <section key={codeudor.id} className="space-y-8">
                  <h2 className="font-bold ">Identidad del codeudor:</h2>
                  <div className="grid gap-4 lg:grid-cols-3">
                    <div className="flex flex-row gap-6 lg:col-span-2">
                      {renderIcon(
                        codeudor.processes[0]?.status ||
                          IDENTITY_STATE.NOT_CREATED
                      )}
                      <div>
                        <p className="mb-1 font-bold">
                          {codeudor.nombreCompleto} / {codeudor.documento}
                        </p>
                        {renderMessage(
                          codeudor.processes[0]?.status ||
                            IDENTITY_STATE.NOT_CREATED,
                          URL.codeudores[codeudor.id] === undefined,
                          codeudor.id
                        )}
                      </div>
                    </div>
                    {URL.codeudores[codeudor.id] && (
                      <ShareURL URL={URL.codeudores[codeudor.id]} />
                    )}
                  </div>
                </section>
              ))}
          </div>
          <div className="flex justify-center p-6">
            <Link to="..">
              <Button className="w-full" onClick={() => {}}>
                {'<'} Regresar
              </Button>
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default Identity;
