import { useAuth } from '../../context/auth-context';
import Emoji from '../../components/shared/Emoji';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/DynamicForm/Button';
import DynamicForm from '../../components/DynamicForm';
import FormService from '../../services/form/form-service';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDialog } from '../../context/dialog-context';
import { DIALOG } from '../../constants/dialog/dialog';
import Loading from '../../components/shared/Loading';
import { useStep } from '../../context/step-context';
import ConsultGeneral from '../../services/consult/consult-general';

const UniversityForm = ({ line }) => {
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [formData, setFormData] = useState();
  const [renderForm, setRenderForm] = useState(false);
  const [submit, setSubmit] = useState(0);
  const methods = useForm();
  const dialog = useDialog();
  const navigate = useNavigate();
  const {
    step: { loanId },
  } = useStep();
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) =>{
    console.log(values);

    const body = {
      formId:values.formId,
      universityId:values.universityId,
      loanId:values.loanId,
      questionsRequired:values.questionsRequired,
      questions:values.questions,
      attachments:values.attachments,
      rol: 'insertQuestionsSql',
    };
    const resul = await ConsultGeneral.postConsultCreditRenovation(body);
    if (resul.length > 0) {
      console.log(resul);
    }
    
    
    FormService.postAnswers(values)
      .then(async () => {
        navigate('./confirmacion');
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });
    };
  const fetchForm = () =>
    FormService.getForm(line)
      .then((response) => {
        setFormData(response);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  useEffect(() => {
    fetchForm();
  }, []);

  useEffect(() => {
    if (loading && formData) setLoading(false);
  }, [formData]);

  return (
    <>
      <h1 className="text-center text-3xl font-bold">
        ¡Hola {user.given_name}{' '}
        <Emoji symbol="waving-hand-light-skin-tone" className="inline h-8" />
        <Emoji symbol="smiling-face-with-smiling-eyes" className="inline h-8" />
        <Emoji symbol="white-exclamation-mark" className="inline h-8" />
      </h1>
      <br />
      {loading ? (
        <Loading />
      ) : renderForm ? (
        <div className="space-y-4">
          <p className="font-bold">
            ¡Queremos conocerte!,{' '}
            <span className="font-normal text-custom-red">
              recuerda que esta información debe contestarla el estudiante que
              quiere aplicar al beneficio.
            </span>
          </p>
          <FormProvider {...methods}>
            <DynamicForm
              formData={formData}
              loanId={loanId}
              onSubmit={onSubmit}
              submit={submit}
            />
          </FormProvider>
          <p className="pt-16">
            <b>Recuerda que tu solicitud entrará en estudio y te daremos respuesta en un promedio de 24 horas.</b>
          </p>
          <div className="flex justify-center p-6">
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
              <Link to="/solicitar-financiacion">
                <Button className="w-full" disabled={isSubmitting}>
                  {'<'} Regresar
                </Button>
              </Link>
              <Button
                className="w-full"
                onClick={() => setSubmit((current) => current + 1)}
                loading={isSubmitting}
              >
                Enviar {'>'}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="font-bold">
            Estás iniciando la aplicación a la línea de financiación con
            beneficio Tasa 0% de interés de tu Universidad como
            #MiembroDeEducaciónEstrella.
          </p>
          <br />
          <p className="font-bold text-custom-green">
            Ten en cuenta estas recomendaciones antes de continuar:{' '}
          </p>
          <br />
          <p>
            1. Todas las preguntas deben ser contestadas{' '}
            <b>exclusivamente por el estudiante</b>, NO por acudientes o
            terceros. <br />
            2. Todas las respuestas deben ser{' '}
            <b>verídicas y podrán ser constatadas</b> en fuentes oficiales de
            información. <br />
            3. Todas las preguntas son obligatorias,{' '}
            <b>ningún campo debe quedar sin respuesta.</b> <br />
            4. Son aproximadamente <b>30 preguntas</b>, que nos permiten
            conocerte mejor a ti y a tu círculo de confianza. <br />
            5. Sólo tendrás <b>1 oportunidad</b> para contestar las preguntas,
            no se podrá repetir bajo ninguna circunstancia o cambiar respuestas.<br/>
            6. Prepárate, esta será la última pregunta de tu formulario: Respóndenos en un 
            corto video personal de máximo 1 minuto y 30 segundos, 1. ¿Con quién vives?, 
            2. ¿De quién dependes económicamente? y 3 ¿Por qué requieres ser beneficiario de la 
            línea tasa cero? Este video deberás subirlo privado a YouTube y compartirnos el video.
          </p>
          <div className="mt-4 flex justify-center space-x-4">
            <Button className="w-40">
              <Link to={'/solicitar-financiacion'}>&lt; Regresar</Link>
            </Button>
            <Button
              customColor="bg-custom-green"
              className="w-40"
              onClick={() => setRenderForm(true)}
            >
              Iniciar &gt;
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default UniversityForm;
