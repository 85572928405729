import Button from '../components/DynamicForm/Button';
import { useAuth } from '../context/auth-context';
import { Cache } from 'aws-amplify';
import Emoji from '../components/shared/Emoji';
import { useNavigate } from 'react-router-dom';
import ZapsignService from '../services/process/zapsign-service';
import { useDialog } from '../context/dialog-context';
import React, { useEffect, useState } from 'react';
import EtapaService from '../services/stage/etapa-service';
import ConsultGeneral from '../services/consult/consult-general';
import Loading from '../components/shared/Loading';

const PageRejeds = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dialog = useDialog();
  const [codeudor, setCodeudor] = useState('');
  const [viewNoApproved, setViewNoApproved] = useState(false);
  const [loading, setLoading] = useState(true);

  const cacheCredit = Cache.getItem('requestCredit');
  const sendChangeCodeudorStudent = async () => {
    const bodyCancel = {
      application_id: cacheCredit.numero,
      final_status: 6,
      final_message: 
      'Aceptado por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      previous_status: cacheCredit.estado.id,
      rol: 'updateOrInsertFinalApplicationStatus',
    };
      await ConsultGeneral.postInsertHistorialEstadoEtapas(bodyCancel)
      .then(async () => {
        const body = {
          creditoNumero: cacheCredit.numero,
          estado: 6,
          mensaje:
            'Aceptado por: ' +
            user.given_name +
            ' ' +
            user.family_name +
            ' ' +
            user.email,
          rol: 'changeCodeudorStudent',
        };
    
        await ZapsignService.postZapsign(body)
          .then(async () => {
            const body = {
              creditoNumero: cacheCredit.numero,
              estado: 7,
              mensaje:
                'Aceptado por: ' +
                user.given_name +
                ' ' +
                user.family_name +
                ' ' +
                user.email,
              rol: 'changeCodeudorStudent',
            };
        
            await ZapsignService.postZapsign(body)
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
    
  };
  const sendChangeCodeudorStudentCancel = async () => {
    const bodyCancel = {
      application_id: cacheCredit.numero,
      final_status: 6,
      final_message: 
      'Aceptado por: ' +
        user.given_name +
        ' ' +
        user.family_name +
        ' ' +
        user.email,
      previous_status: cacheCredit.estado.id,
      rol: 'updateOrInsertFinalApplicationStatus',
    };
      await ConsultGeneral.postInsertHistorialEstadoEtapas(bodyCancel)
      .then(async () => {
        const body = {
          creditoNumero: cacheCredit.numero,
          estado: 6,
          mensaje:
            'Desistido por: ' +
            user.given_name +
            ' ' +
            user.family_name +
            ' ' +
            user.email,
          rol: 'changeCodeudorStudent',
        };
    
        await ZapsignService.postZapsign(body)
          .then(() => {
            navigate('/solicitar-financiacion');
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
    
  };
  const showDialog = (message, callback) => {
    dialog.confirmation(
      <div className="space-y-4 text-center">
        <Emoji symbol="warning" className="inline h-12" />
        <p className="font-bold">{message}</p>
      </div>,
      { onConfirm: callback }
    );
  };

  useEffect(() => {
    const getChangeCodeudor = async () => {
      if (cacheCredit && user) {
        await EtapaService.get({ usuarioId: user.sub })
          .then(async (response) => {
            if (typeof response.loanId === 'undefined') {
              navigate('/solicitar-financiacion');
            } else {
              const body = {
                creditId: cacheCredit.numero,
                rol: 'getChangeCodeudor',
              };
              await ZapsignService.postZapsign(body)
                .then((res) => {
                  if (res[0].unapprovedReason === 'No es sujeto de financiación') {
                    setViewNoApproved(true);
                  } else {
                    setCodeudor(res[0].updaterUserId);
                    
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error);
                });
            }

            return response;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };
    getChangeCodeudor();
  }, []);
  const redirectToSolicitud = () => {
    navigate('/solicitar-financiacion');
  };
  return loading ? (
    <Loading />
  ) : (
    <>
      {viewNoApproved ? 
        <section>
        <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
          Estado de tu solicitud: No Aprobada
        </h2>
        <h2 className="pb-6 text-center text-xl font-bold">
          Información importante para ti
        </h2>
        <h4 className=" text-center">
          Queremos agradecerte por confiar en Educación Estrella® 
          para alcanzar tus metas. Después de analizar tu solicitud de financiación, 
          lamentamos informarte que no ha sido aprobada.
        </h4>

        <br />
          <br />

        <h4 className=" text-center">
          <p>
            Sabemos que este es solo un paso en tu camino y estaremos aquí para acompañarte en 
            el futuro. Recuerda que podrás presentar una nueva solicitud en el próximo 
            semestre académico.
          </p>
          <br />
          <br />
        </h4>

        <div className="my-4 text-center">
          <Button
            onClick={redirectToSolicitud
              
            }
          >
            Atrás
          </Button>
        </div>
        </section>
      :
        <section>
          <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
            Propuesta de cambio en tu solicitud
          </h2>
          <h2 className="pb-6 text-center text-xl font-bold">
            Información importante para ti
          </h2>
          <h4 className=" text-center">
            Tu solicitud no fue aprobada en las condiciones actuales. Sin embargo, queremos que seas 
            #MiembroDeEducaciónEstrella. Por eso, te ofrecemos una última alternativa: <b>
              volver a crear tu solicitud realizando estos cambios:
            </b>
          </h4>

          <br />

          <p>
            <h2 className="pb-6 text-center text-xl font-bold text-custom-green">
              Cambios que debes realizar:
            </h2>
          </p>


          <h4 className=" text-center">
            <b>{codeudor}</b>
            <br />
            <br />
          </h4>

          <h4 className=" text-center">
            <p>
              Al seleccionar <b>Acepto Cambiar</b>, podrás presentar una nueva solicitud (por única vez).
            </p>
            <p>
              Si eliges <b>Desistir Solicitud</b>, tu solicitud será cancelada, y solo podrás volver a aplicar el próximo semestre.
            </p>
            <br />
            <br />
          </h4>

          <div className="my-4 text-center">
            <Button
              className="mr-4"
              onClick={() =>
                showDialog(
                  '¿Confirmas el DESISTIMIENTO de tu solicitud?',
                  sendChangeCodeudorStudentCancel
                )
              }
            >
              Desistir Solicitud
            </Button>
            <Button
              onClick={() =>
                showDialog(
                  '¿Confirmas el cambios que debes realizar?',
                  sendChangeCodeudorStudent
                )
              }
            >
              Acepto Cambiar
            </Button>
          </div>
        </section>
      
      }
    </>
  );
};

export default PageRejeds;
