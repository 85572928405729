import React from 'react';
import ToggleSwitch from './ToggleSwitch';
import { ReactComponent as Slogan } from '../../assets/images/header/slogan.svg';
import { ReactComponent as Logo } from '../../assets/images/header/logo.svg';
import USBLightLogo from '../../assets/images/header/usb-logo-light.png';
import USBDarkLogo from '../../assets/images/header/usb-logo-dark.png';
import { ReactComponent as ExternadoLogo } from '../../assets/images/header/externado-logo.svg';
import { ReactComponent as MemberLogo } from '../../assets/images/header/member-logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { useDarkMode } from '../../context/dark-mode-context';
import Button from '../DynamicForm/Button';
import { MdClose } from 'react-icons/md';
import PaymentModal from '../dialog/PaymentModal';
import { useDialog } from '../../context/dialog-context';
import { ReactComponent as UTBLogoAzul } from '../../assets/images/header/utb_logo_azul.svg';
import { ReactComponent as UTBLogoClaro } from '../../assets/images/header/utb_logo_claro.svg';
import ZapsignService from '../../services/process/zapsign-service';
import { useEffect, useState } from 'react';
import { ReactComponent as UpbLogo } from '../../assets/images/header/upb_logo.svg';
import { ReactComponent as UpbLogoBlanco } from '../../assets/images/header/ColorLogoUPB.svg';
import { ReactComponent as CucLogo } from '../../assets/images/header/Logo_cuc_vertical.svg';
import { ReactComponent as CucLogoBlanco } from '../../assets/images/header/LOGOCUCCOLOR.svg';

function Header({ className }) {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const location = useLocation();
  const dialog = useDialog();
  const [message, setMessage] = useState('');
  const [viewEmergency, setViewEmergency] = useState(false);
  useEffect(() => {
    const updateNotification = async () => {
      const body = {
        status: 1,
        rol: 'getInfoStudentNotification',
      };
      await ZapsignService.postZapsign(body)
        .then((res) => {
          if (res.length > 0) {
            setViewEmergency(true);
            setMessage(res[0].message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    updateNotification();
  }, []);
  const showPayment = () =>
    dialog.custom(
      <>
        <div className="flex justify-end">
          <button onClick={() => dialog.close()}>
            <MdClose className="h-auto w-6" />
          </button>
        </div>
        {/* <p>
        Actualmente estamos presentando unos inconvenientes con la pasarela de pagos PSE. 
        Por eso te sugerimos que puedas realizar tu pago en la opción Efectivo y Puntos Físicos 
        (Efecty, Paga Todo o a través del convenio con Bancolombia).
        <br />
        <br />
        Conoce cómo puedes hacer tu pago, haciendo <a href='https://youtu.be/GXE0Zc2I8Z0?si=iOZdbNkkKEIRYF0e' 
        target="_blank"
        rel="noreferrer"
        className="text-custom-purple hover:underline dark:text-custom-green">clic aquí</a>:
        
        </p>
        <br /> */}
        <PaymentModal />
      </>,
      { className: 'p-2', spacing: false }
    );
  const showInfo = () =>
    dialog.custom(
      <>
        <div className="flex justify-end">
          <button onClick={() => dialog.close()}>
            <MdClose className="h-auto w-6" />
          </button>
        </div>
        <div className="flex justify-center">
          <p className="text-justify">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </p>
        </div>
      </>,
      { className: 'p-10', spacing: false }
    );
  const renderLogo = () => {
    if (location.pathname.startsWith('/simulador/usb'))
      return (
        <div className="flex flex-row justify-center divide-x-1 divide-custom-blue dark:divide-custom-white md:justify-start">
          <span className="pr-6">
            <img
              src={darkMode ? USBDarkLogo : USBLightLogo}
              alt="background"
              className="h-16 w-auto"
            />
          </span>
          <Link to="/" className="h-min w-min pl-6">
            <MemberLogo className="h-16 w-auto" />
          </Link>
        </div>
      );
    else if (location.pathname.startsWith('/simulador/externado'))
      return (
        <div className="flex flex-row justify-center divide-x-1 divide-custom-blue dark:divide-custom-white md:justify-start">
          <span className="pr-6">
            <ExternadoLogo className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
          </span>
          <Link to="/" className="h-min w-min pl-6">
            <MemberLogo className="h-16 w-auto" />
          </Link>
        </div>
      );
    else if (location.pathname.startsWith('/simulador/utb'))
      return (
        <div className="flex flex-row justify-center divide-x-1 divide-custom-blue dark:divide-custom-white md:justify-start">
          <span className="pr-6">
            {darkMode ? (
              <UTBLogoClaro className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
            ) : (
              <UTBLogoAzul className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
            )}
          </span>
          <Link to="/" className="h-min w-min pl-6">
            <MemberLogo className="h-16 w-auto" />
          </Link>
        </div>
      );
    else if (location.pathname.startsWith('/simulador/tech'))
      return (
        <div className="flex flex-row justify-center divide-x-1 divide-custom-blue dark:divide-custom-white md:justify-start">
          <span className="pr-6">
            <ExternadoLogo className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
          </span>
          <Link to="/" className="h-min w-min pl-6">
            <MemberLogo className="h-16 w-auto" />
          </Link>
        </div>
      );
    else if (location.pathname.startsWith('/simulador/cuc/barranquilla'))
      return (
        <div className="flex flex-row justify-center divide-x-1 divide-custom-blue dark:divide-custom-white md:justify-start">
          <span className="pr-6">
          {darkMode ? (
            <CucLogo className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
          ) : (
              <CucLogoBlanco className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
            )}
            
          </span>
          <Link to="/" className="h-min w-min pl-6">
            <MemberLogo className="h-16 w-auto" />
          </Link>
        </div>
      );
    else if (location.pathname.startsWith('/simulador/upb/bucaramanga'))
      return (
        <div className="flex flex-row justify-center divide-x-1 divide-custom-blue dark:divide-custom-white md:justify-start">
          <span className="pr-6">
          {darkMode ? (
            <UpbLogo className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
          ) : (
              <UpbLogoBlanco className="h-16 w-auto fill-current text-[#002E19] dark:text-custom-white" />
            )}
          </span>
          <Link to="/" className="h-min w-min pl-6">
            <MemberLogo className="h-16 w-auto" />
          </Link>
        </div>
      );
    else
      return (
        <div className="flex justify-center md:justify-start">
          <Link to="/">
            <Logo className="h-16 w-auto" />
          </Link>
        </div>
      );
  };
  return (
    <>
      {viewEmergency && (
        <div className="ribbon ribbon-blue-to-default theme-light bg-custom-red">
          <div className="ribbon-drop-wrapper">
            <div className="ribbon-content-wrapper">
              <div className="ribbon-content row">
                <div className="column large-12 small-11 large-centered">
                  <p className="text-center">
                    Tenemos una incidencia que podría impactar en tus servicios
                    <Button
                      onClick={showInfo}
                      customColor="bg-custom-red"
                      className="px-10 text-lg"
                    >
                      VER MÁS
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <header className={'flex flex-col space-y-6 py-5' + ` ${className}`}>
        <div className="flex justify-end">
          <ToggleSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        </div>
        <div className="grid grid-cols-1 items-center justify-center gap-6 md:grid-cols-2">
          {renderLogo()}
          <div className="flex items-center justify-around gap-5 md:flex-row md:justify-end">
            {!location.pathname.startsWith('/solicitar-financiacion') && (
              <div>
                <Button
                  onClick={showPayment}
                  customColor="bg-custom-purple"
                  className="px-10 text-lg"
                >
                  Realizar
                  <br /> Pago
                </Button>
              </div>
            )}
            <Slogan className="h-12 w-auto fill-current text-light dark:text-dark md:h-20" />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
